/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IdDto, QnaDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Qna<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Q&A 상세 조회
   *
   * @tags qna
   * @name GetQna
   * @summary Q&A 상세 조회
   * @request GET:/v1/qnas/{qnaId}
   * @secure
   */
  getQna = (qnaId: number, params: RequestParams = {}) =>
    this.request<QnaDto, any>({
      path: `/v1/qnas/${qnaId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Q&A 답변
   *
   * @tags qna
   * @name UpdateQna
   * @summary Q&A 답변
   * @request PUT:/v1/qnas/{qnaId}
   * @secure
   */
  updateQna = (qnaId: number, data: QnaDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/qnas/${qnaId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Q&A 리스트 조회<br><br>문의타입:qnaType(이용문의:QUESTION, 버그:BUG)<br>문의카테고리:categoryType(앱설정:APP, 장터:MARKET, 물물교환:BARTER, 번영회:COMMUNITY, 부동산:PROPERTY, 톡:TALK)
   *
   * @tags qna
   * @name GetQnas
   * @summary Q&A 리스트 조회
   * @request GET:/v1/qnas
   * @secure
   */
  getQnas = (
    query?: {
      qnaType?: ('QUESTION' | 'BUG')[];
      categoryType?: ('APP' | 'MARKET' | 'BARTER' | 'COMMUNITY' | 'PROPERTY' | 'TALK')[];
      isAnswer?: boolean;
      startDate?: string;
      endDate?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<QnaDto[], any>({
      path: `/v1/qnas`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
