/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import { makeObservable, action, flow } from 'mobx';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

class ServiceStoreBase {
  isBusy: boolean;

  constructor() {
    makeObservable(this, {
      handleByStatus: action,
      getItem: flow,
      createItem: flow,
      updateItem: flow,
      deleteItem: flow,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleByStatus(
    response: AxiosResponse,
    params?: { successMessage?: string; failMessage?: string },
  ) {
    /** Success */
    if (response.status === 200) {
      if (typeof params?.successMessage !== 'undefined') {
        toast(params.successMessage);
      }
      return true;
    }

    /** Client Error */
    if (response.status >= 400 && response.status < 500) {
      if (typeof params?.failMessage !== 'undefined') {
        toast.error(params.failMessage);
      } else {
        toast.error(response.data.errorMessage);
      }
      return false;
    }

    /** Server Error */
    if (response.status >= 500) {
      if (typeof params?.failMessage !== 'undefined') {
        toast.error(params.failMessage);
      } else {
        toast.error(response.data.errorMessage);
      }
      return false;
    }
  }

  getItem(itemId: number) {}

  createItem(item: any) {}

  updateItem(itemId: number, item: any) {}

  deleteItem(itemId: number) {}
}

export default ServiceStoreBase;
